import React from "react"

function FormEuroaccident() {
  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.MERGE0(event)
    }
  }

  return (
    <form
      id="signUpForm"
      action="https://jointacademy.us14.list-manage.com/subscribe/post"
      method="post"
      className="validate form margin-top--sm"
      data-goal="Form Submitted"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="u" value="af6b2530d423199a95fe20a13" />
      <input type="hidden" name="id" value="78d5b0c98f" />
      <div>
        <div className="grid grid--gap-sm">
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="mce-FNAME"
              autoCapitalize="off"
              autoCorrect="off"
              name="FNAME"
              id="mce-FNAME"
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              placeholder="Förnamn "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Förnamn ")}
              required
            />
          </div>
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="mce-LNAME"
              autoCapitalize="off"
              autoCorrect="off"
              name="LNAME"
              id="mce-LNAME"
              className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
              placeholder="Efternamn "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Efternamn ")}
              required
            />
          </div>
        </div>
        <div className="grid grid--gap-sm">
          <div className="col--md-6">
            <input
              type="email"
              htmlFor="mce-EMAIL"
              autoCapitalize="off"
              autoCorrect="off"
              name="EMAIL"
              id="mce-EMAIL"
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              placeholder="E-postadress "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "E-postadress ")}
              required
            />
          </div>
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="mce-MMERGE5"
              autoCapitalize="off"
              autoCorrect="off"
              name="MMERGE5"
              id="mce-MMERGE5"
              className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
              placeholder="Personnummer "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Personnummer ")}
              required
            />
          </div>
        </div>
        <div className="grid grid--gap-sm">
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="mce-PHONE"
              autoCapitalize="off"
              autoCorrect="off"
              name="PHONE"
              id="mce-PHONE"
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              placeholder="Telefonnummer "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Telefonnummer ")}
              required
            />
          </div>
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="mce-MMERGE6"
              autoCapitalize="off"
              autoCorrect="off"
              name="MMERGE6"
              id="mce-MMERGE6"
              className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
              placeholder="Adress "
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Adress ")}
              required
            />
          </div>
        </div>
        <div className="flex flex--start margin--none">
          <input
            id="mc-embedded-subscribe"
            type="submit"
            value="Skicka ansökan"
            name="submit"
            className="btn btn--fullwidth btn--md btn--primary"
          />
        </div>
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_af6b2530d423199a95fe20a13_78d5b0c98f"
            tabIndex="-1"
            defaultValue=""
          />
        </div>
      </div>
    </form>
  )
}

export default FormEuroaccident
