import React from 'react';
import Logo from '@assets/euroaccident.svg';
import Segment from '../../components/segment';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import SocialProofQuoteCardST from '../../components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Testimonial from '../../components/sections/testimonial/testimonial';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import FormEuroaccident from '../../components/FormEuroaccident';
import Treatments from '../../components/staticSections/Treatments/Treatments';

const IndexPage = () => {
  const lang = 'se_sv';

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Euroaccident" src={Logo} />
      }
      dataToken
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />
      <Seo title="Euroaccident" language="sv" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedataNoCerts
        dataSection="hero_CTA"
        alt="blue shield of california"
        title="Behandla artros direkt i mobilen"
        titleClassName="bold"
        text="Med Joint Academy får du ett skräddarsytt träningsprogram för att minska din ledsmärta."
        calldataGoal="CTA Clicked"
        callhref="#signUpForm"
        callclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        calltext="KOM IGÅNG IDAG"
      />
      <SocialProofQuoteCardST
        lang={lang}
        dataTheme="white"
        extraMargin="margin-top--lg"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="kom igång idag"
        ctaDataGoal="CTA Clicked"
        ctaHref="#signUpForm"
      />
      <BgCardsTextST dataTheme="light" lang={lang} />
      <Treatments lang={lang} downloadhref="#herotext" />
      <SocialProofFeedST lang={lang} />
      <Testimonial
        dataTheme="white"
        renderTitle
        titleClassName=""
        videoSrcURL="https://player.vimeo.com/video/332237175?color=fff&title=0&byline=0&portrait=0"
        videoTitle="“I can’t imagine a day without Joint Academy”"
      />
      <LicensedPtSliderST
        lang={lang}
        downloaddataGoal="CTA Clicked"
        downloadhref="#signUpForm"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Kom igång idag"
      />
      <OutcomeST lang={lang} />
      <section data-theme="ice-blue">
        <div className="container container--md margin-top--lg margin-bottom--lg">
          <div className="text--component text--center margin-bottom--sm">
            <h2 className="margin-bottom--sm text--center">
              Anmäl dig till Joint Academys behandling för artros
            </h2>

            <p>
              Fyll i din information nedan. Du kommer inom ett par dagar att få
              en inbjudan via mail som du följer för att registrera dig. Du
              kommer sedan att få kontakt med en av våra leg. fysioterapeuter.
            </p>
          </div>
          <div className="grid grid--gap-sm ">
            <div className="grid col--md-10">
              <FormEuroaccident />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
